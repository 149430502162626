import { action, useSubmission } from "@solidjs/router";
import { Match, Show, Switch } from "solid-js";
import { OTPForm } from "~/components/custom/otp-form";
import { Button } from "~/components/solid-ui/button";
import { sendOTP } from "~/lib/email/send-otp";
import { checkOTP } from "~/lib/db/otp";
import { clientOnly } from "@solidjs/start";

const ConfettiExplosion = clientOnly(() =>
  import("solid-confetti-explosion").then((mod) => ({
    default: mod.ConfettiExplosion,
  }))
);

const verifyOTP = action(async (data: FormData) => {
  "use server";

  const otp = data.get("otp") as string;
  const { status } = await checkOTP(otp);

  return { status };
});

export default function Admin() {
  const otpSubmission = useSubmission(verifyOTP);
  let form!: HTMLFormElement;

  return (
    <div class="w-full min-h-screen grid place-items-center py-20">
      <div class="flex flex-col items-center justify-center">
        <h1 class="text-4xl">Admin</h1>
        <div class="pt-20 grid place-items-center gap-20">
          <p class="text-xl max-w-prose text-center px-10">
            We sent you a One-Time Password (OTP) to your email. Please enter
            the code below to verify your email.
          </p>

          <form id="otpSubmission" method="post" action={verifyOTP} ref={form}>
            <Show
              when={otpSubmission.pending}
              fallback={
                <OTPForm
                  ref={form}
                  onComplete={() => {
                    form.requestSubmit();
                  }}
                />
              }
            >
              <span>Verifying...</span>
            </Show>
          </form>

          <Switch>
            <Match when={otpSubmission.result?.status === "valid"}>
              <>
                <ConfettiExplosion particleCount={200} force={0.3} />
                <span>OTP is correct!</span>
              </>
            </Match>
            <Match when={otpSubmission.result?.status === "invalid"}>
              <span>OTP is incorrect!</span>
            </Match>
            <Match when={otpSubmission.result?.status === "expired"}>
              <>
                <span>OTP is expired! Request a new one.</span>
              </>
            </Match>
            <Match when={otpSubmission.result?.status === "error"}>
              <span>OTP is incorrect!</span>
            </Match>
          </Switch>

          <Show when={otpSubmission.error}>
            <span>Error: {otpSubmission.error.message}</span>
          </Show>
        </div>
      </div>
      <Button
        onClick={() => {
          sendOTP({
            email: "atila@fassina.eu",
            name: "Atila",
          });
        }}
      >
        Send OTP token
      </Button>
    </div>
  );
}
