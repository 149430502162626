"use server";

import { otpEmail } from "./solid-to-email";
import { refId } from "../db/token";
import { resend } from "./setup";
import { insertOTP } from "../db/otp";

interface SendEmailParams {
  email: string;
}

export async function sendOTP({ email: to }: SendEmailParams) {
  try {
    const { token } = await insertOTP(to);

    if (!token) throw new Error("Error generating OTP");

    const { data, error } = await resend.emails.send({
      from: "Atila Fassina <no-reply@atila.io>",
      to,
      subject: "[ListUP]: OTP",
      html: otpEmail({ otpToken: token }),
      tags: [
        {
          name: "action",
          value: "verification",
        },
      ],
      headers: {
        "X-Entity-Ref-ID": refId(),
      },
    });

    if (error) {
      console.error(error);
      return;
    }

    console.info({ data });
  } catch (e) {
    console.error(e);
  }
}
