import {
  OTPField,
  OTPFieldGroup,
  OTPFieldInput,
  OTPFieldProps,
  OTPFieldSeparator,
  OTPFieldSlot,
  REGEXP_ONLY_DIGITS,
} from "~/components/solid-ui/otp-field";

export function OTPForm(props: { onComplete?: OTPFieldProps["onComplete"] }) {
  return (
    <OTPField maxLength={6} onComplete={props.onComplete}>
      <OTPFieldInput id="otp" name="otp" pattern={REGEXP_ONLY_DIGITS} />
      <OTPFieldGroup>
        <OTPFieldSlot index={0} />
        <OTPFieldSlot index={1} />
        <OTPFieldSlot index={2} />
      </OTPFieldGroup>
      <OTPFieldSeparator />
      <OTPFieldGroup>
        <OTPFieldSlot index={3} />
        <OTPFieldSlot index={4} />
        <OTPFieldSlot index={5} />
      </OTPFieldGroup>
    </OTPField>
  );
}
